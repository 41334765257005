<template>
    <div class="container-fluid p-4">
        <div class="row row-cols-1 mb-n4">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Basic Spinner</div>
                    <div class="card-body">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Contextual Variations</div>
                    <div class="card-body">
                        <div class="spinner-border text-primary mr-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-border text-secondary  mr-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-border text-success  mr-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-border text-danger  mr-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-border text-warning  mr-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-border text-info  mr-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-border text-light  mr-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-border text-dark mr-2" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Spinner / Sizes</div>
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div class="spinner-border text-primary spinner-border-sm mr-4" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-border text-primary mr-4" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-border text-primary" role="status" style="width: 3rem; height: 3rem;">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Spinner / Buttons</div>
                    <div class="card-body">
                        <div>
                            <button class="btn btn-primary btn-spinner mr-2" type="button">
                                <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
                                <span class="sr-only">Loading...</span>
                            </button>
                            <button class="btn btn-primary btn-spinner" type="button">
                                <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
                                <span class="ml-3">Loading...</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Spinners'
}
</script>
